import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    name: String,
    properties: Object,
  };

  track() {
    if (analytics !== "undefined") {
      analytics.track(this.nameValue, this.propertiesValue);
    }
  }
}
