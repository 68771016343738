import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];
  static values = { index: { type: Number, default: 0 } };

  change() {
    this.indexValue = this.tabTargets.indexOf(event.currentTarget);
  }

  indexValueChanged() {
    this.showTab();
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index];
      tab.classList.toggle("c-tab-list__tab--active", index == this.indexValue);
      panel.hidden = index != this.indexValue;
    });
  }
}
