import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton", "input"];

  initialize() {
    this.submitButtonTarget.hidden = true;
  }

  submit() {
    if ("requestSubmit" in HTMLFormElement.prototype) {
      this.element.requestSubmit();
    } else {
      this.element.submit();
    }
    this.inputTargets.forEach((element) => (element.disabled = true));
  }
}
