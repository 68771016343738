import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    placeholder: String,
  };

  connect() {
    new TomSelect(this.element, this.settings());
  }

  settings() {
    return {
      maxOptions: null,
      placeholder: this.placeholderValue,
    };
  }
}
