import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

application.handleError = (error, message, detail) => {
  console.warn(message, detail);
  Honeybadger.notify(error);
};

export { application };
