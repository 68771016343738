import { Controller } from "@hotwired/stimulus";

export default class WistiaController extends Controller {
  static values = {
    watched: Boolean,
    videoId: String,
  };

  initialize() {
    // If a videoId is present, we can embed the video ourselves. Otherwise we
    // wait for another controller (e.g. the CourseController) to tell us which
    // video to embed.
    if (this.videoIdValue) {
      this.embedVideo(this.videoIdValue, false);
    }
  }

  embedVideo(wistiaId) {
    this.element.id = `wistia_${wistiaId}`;
    this.watchedValue = false;

    const opts = this.wistiaOpts();
    const video = Wistia.embed(wistiaId, opts);

    this.bindEvents(video);
    this.element.dispatchEvent(new Event("videochanged"));

    if (this.isAutoplay()) {
      video.play();
    }
  }

  bindEvents(video) {
    video.bind("play", () => {
      this.element.dispatchEvent(new Event("play"));
    });

    video.bind("percentwatchedchanged", (percent) => {
      const watchedThreshold = 0.9;
      if (percent > watchedThreshold && !this.watchedValue) {
        this.element.dispatchEvent(new Event("watchedtocompletion"));
        this.watchedValue = true;
      }
    });

    video.bind("end", () => {
      this.element.dispatchEvent(new Event("nextsection"));
    });
  }

  isAutoplay() {
    const query = new URLSearchParams(window.location.search);
    return !!query.get("autoplay");
  }

  wistiaOpts() {
    const playerColor = getComputedStyle(
      document.documentElement,
    ).getPropertyValue("--color-wistia-player");

    const opts = {
      playerColor: playerColor,
      videoFoam: true,
      playbackRateControl: false,
    };

    return opts;
  }
}
