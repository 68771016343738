import { Controller } from "@hotwired/stimulus";

function isEduEmail(email) {
  return RegExp(".edu").test(email);
}

export default class extends Controller {
  static targets = ["confirmationBox", "emailInput"];

  confirmation() {
    if (isEduEmail(this.emailInputTarget.value)) {
      this.confirmationBoxTarget.hidden = false;
    } else {
      this.confirmationBoxTarget.hidden = true;
    }
  }
}
