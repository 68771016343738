export function notifyAssistiveTech(message, assertive = false) {
  const liveRegionId = assertive
    ? "js-assistive-tech-notifications-assertive"
    : "js-assistive-tech-notifications";
  const liveRegion = document.getElementById(liveRegionId);

  if (liveRegion) {
    liveRegion.textContent = message;
  }
}
