import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.observer = new ResizeObserver((entries) => {
      let hasOverflow = false;

      for (const entry of entries) {
        hasOverflow ||=
          entry.target.scrollHeight > entry.target.clientHeight ||
          entry.target.scrollWidth > entry.target.clientWidth;
      }

      if (hasOverflow) {
        this.element.tabIndex = 0;
      } else {
        this.element.removeAttribute("tabIndex");
      }
    });

    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.disconnect();
  }
}
