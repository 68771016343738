import { Controller } from "@hotwired/stimulus";

function getThumbnailUrl(id) {
  const url = encodeURIComponent(
    `https://hotshotlegal.wistia.com/medias/${id}`,
  );

  return `https://fast.wistia.com/oembed?url=${url}`;
}

export default class extends Controller {
  static values = {
    videoId: String,
  };

  connect() {
    fetch(getThumbnailUrl(this.videoIdValue))
      .then((response) => response.json())
      .then((data) => (this.element.src = data.thumbnail_url));
  }
}
