import { Controller } from "@hotwired/stimulus";
import { notifyAssistiveTech } from "./../../helpers/notify_assistive_tech";

export default class extends Controller {
  static values = {
    assistiveTechNotification: String,
    initialButtonText: String,
    successButtonText: String,
    textToCopy: String,
  };

  copy() {
    const textToCopy = this.textToCopyValue;

    navigator.clipboard.writeText(textToCopy).then(() => {
      this.brieflySwapButtonText();
      notifyAssistiveTech(this.assistiveTechNotificationValue);
    });
  }

  brieflySwapButtonText() {
    if (this.hasSuccessButtonTextValue && this.hasInitialButtonTextValue) {
      this.updateButtonText(this.successButtonTextValue);

      setTimeout(() => {
        this.updateButtonText(this.initialButtonTextValue);
      }, 3000);
    }
  }

  updateButtonText(text) {
    this.element.innerHTML = text;
  }
}
