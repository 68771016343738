import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["swapping"];
  static targets = ["content"];

  toggleClass() {
    this.contentTarget.classList.toggle(this.swappingClass);
  }

  toggleHidden(event) {
    this.contentTarget.toggleAttribute("hidden");
    event.target.setAttribute("aria-expanded", "true");
  }
}
