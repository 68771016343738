import "@hotwired/turbo-rails";
import "invokers-polyfill";

import { configValue } from "./helpers/config_value";

const Honeybadger = require("@honeybadger-io/js");

Honeybadger.configure({
  apiKey: configValue("honeybadger-api-key"),
  environment: configValue("rails-env"),
});

Honeybadger.setContext({
  user_id: configValue("user-id"),
  user_email: configValue("user-email"),
});

Turbo.session.drive = false;

import "./controllers/application/index";
