import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["video"];
  static values = {
    completePathTemplate: String,
    isPreview: Boolean,
  };

  initialize() {
    if (!this.isPreviewValue) {
      this.videoTarget.addEventListener("videochanged", () => {
        this.notifyWhenWatchedToCompletion();
      });
    }
  }

  notifyWhenWatchedToCompletion() {
    this.videoTarget.addEventListener(
      "watchedtocompletion",
      () => this.notifyOfCompletion(),
      { once: true },
    );
  }

  notifyOfCompletion() {
    post(this.completePath(), {
      contentType: "application/json",
    });
  }

  completePath() {
    const sectionId = window.location.pathname.split("/sections/")[1];
    const template = this.completePathTemplateValue;
    return template.replace("SECTION_ID", sectionId);
  }
}
